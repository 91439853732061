import React from "react";
import StepFour from "../../components/cards/onboardingCard/stepFour";
import "./style.scss";
import { LiveChatWidget } from '@livechat/widget-react'
import StepThree from "../../components/cards/onboardingCard/stepThree";

const BrandFocus = () => {
  return (
    <div className="bg bg-img">
      <div className="mid-card card-4">
        {/* <StepFour /> */}
        <StepThree />
      </div>
      {/* <LiveChatWidget
      license="14411064"
      visibility="minimized"
    /> */}
    </div>
  );
};

export default BrandFocus;
