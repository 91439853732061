import React, { useEffect, useState } from "react";
import { Link, navigate } from "gatsby";
import { connect } from "react-redux";

import Title from "../../../text/title";
import LinkText from "../../../text/linkText";
import "./style.scss";
import OptionCard from "../../optionCard";
import Input from "../../../input";
import NavRight from "../../../button/navRight";
import NavLeft from "../../../button/navLeft";
import { addNewBrand as addBrand } from "../../../../actions/brand/action";

const StepFour = ({ addBrand, focus, brand }) => {
  const [isMobile, setMobile] = useState(false);
  const [brandFocus, updateBrandFocus] = useState("");

    useEffect(() => {
      if (window.innerWidth < 576) setMobile(true);
      const handleWindowSizeChange = () => {
        window.innerWidth < 576 ? setMobile(true) : setMobile(false);
      };
      window.addEventListener("resize", handleWindowSizeChange);
      return () => {
        window.removeEventListener("resize", handleWindowSizeChange);
      };
    }, []);
  useEffect(() => {
    if (brand.focus) navigate("/onboarding/brand-age");
  }, [focus]);

  const setBrandfocus = async () => {
    if (!brandFocus) return;
    await addBrand({ focus: brandFocus });
  };

  return (
    <div className="onboarding-step-four row">
      <div className="top-step-four">
        {isMobile ? (
          <img className="logo" src="/images/common/logo.svg" />
        ) : (
          <img src="/images/common/close.svg" />
        )}
      </div>
      <div className="col-md-6 col-lg-6 col-sm-6 col-12 r-image sofi-parent sofi">
        <div className="d-flex justify-content-center">
          <img
            className="img img-fluid sofia-img"
            src="/images/common/sofia.svg"
            alt="Sorry! Sofia couldn't come :("
          />
        </div>

        <div className="d-flex justify-content-center">
          <div style={{ textAlign: "center", marginTop: "15px" }}>
            <LinkText text="Nice to meet you Joey!" className="o-subtitle" />
          </div>
        </div>
      </div>
      <div className="col-md-6 col-lg-6 col-sm-6 col-12  step-content">
        <div className="brand-focus-heading">
          <Title text="What is your brand focus?" />
        </div>

        <div className="row option-spacing">
          <div className="col-4">
            <OptionCard
              style={
                brandFocus === "Apparel" ? { background: "#31ffea" } : null
              }
              name="Apparel"
              onClick={() => updateBrandFocus("Apparel")}
            />
          </div>
          <div className="col-4">
            <OptionCard
              style={
                brandFocus === "Accessory" ? { background: "#31ffea" } : null
              }
              name="Accessory"
              onClick={() => updateBrandFocus("Accessory")}
            />
          </div>
          <div className="col-4">
            <OptionCard
              style={
                brandFocus === "Textile" ? { background: "#31ffea" } : null
              }
              name="Textile"
              onClick={() => updateBrandFocus("Textile")}
            />
          </div>
        </div>
        <div className="row option-spacing">
          <div className="col-4">
            <OptionCard
              style={brandFocus === "Beauty" ? { background: "#31ffea" } : null}
              name="Beauty"
              onClick={() => updateBrandFocus("Beauty")}
            />
          </div>
          <div className="col-4">
            <OptionCard
              style={
                brandFocus === "Health & Wellness"
                  ? { background: "#31ffea" }
                  : null
              }
              name="Health &amp; Wellness"
              onClick={() => updateBrandFocus("Health & Wellness")}
            />
          </div>
          <div className="col-4 option-ip">
            <Input
              placeholder="or type here"
              onChange={(e) => updateBrandFocus(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className={!isMobile ? "row" : ""}>
        <div className="col-12 col-md-6 col-sm-6 col-xl-6 to-right hpx"></div>
        <div className="col-12 col-md-6 col-sm-6 col-xl-6 to-left p-30">
          <div>
            <span
              className="text-decoration-none"
              to="/onboarding/user-details"
            >
              <NavLeft />
              <span className="nav-text">Back</span>
            </span>
          </div>
          <div>
            <span
              className="text-decoration-none"
              onClick={() => setBrandfocus()}
            >
              <span className="nav-text">Next</span>
              <NavRight />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = ({ brand }) => ({
  focus: brand.focus,
  brand: brand,
});
export default connect(mapStateToProps, { addBrand })(StepFour);
